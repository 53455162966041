import * as Sentry from '@sentry/react';
import type { CaptureContext, Extras } from '@sentry/types';
import type { SeverityLevel } from '@sentry/react';

export const logSentry = (
  error: Error,
  extraInfo?: Extras,
  level?: SeverityLevel
) => {
  Sentry.captureException(error, {
    level,
    extra: { ...extraInfo },
  });
};

export const logSentryMessage = (
  msg: string,
  captureContext?: CaptureContext | SeverityLevel
) => {
  Sentry.captureMessage(msg, captureContext);
};
