export const VERSION = process.env.NEXT_PUBLIC_APP_VERSION;

export const COMMIT_HASH: string = process.env.NEXT_PUBLIC_COMMIT_HASH;

export const GTM_ID: string = process.env.NEXT_PUBLIC_GTM_ID;

export const SENTRY_DSN_URL: string = process.env.NEXT_PUBLIC_SENTRY_DSN_URL;

export const ADMIN_ASK_FOR_INTERVIEW_ENABLED = false;

export const IS_KYC_STATS_ENABLED = false;

export const USE_NEW_ACTIVITIES = true;

export const DECASH_ENABLED = false;
