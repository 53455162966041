import type { Roles } from 'src/@types/Roles';

export const isAdmin = (role: Roles) => {
  return role === 'ADMIN' || role === 'SUPER_ADMIN';
};
export const isAffiliate = () => {
  return window?.location.pathname.includes('/affiliate');
};
export const isClient = (role: Roles) => {
  return role === 'CLIENT';
};
export const isSuperAdmin = (role: Roles) => {
  return role === 'SUPER_ADMIN';
};
