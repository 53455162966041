import { isAffiliate } from 'src/utils/helpers/roles';
import type { Path } from 'src/routes/types';
import type { Roles } from 'src/@types/Roles';
import { getUserRole } from 'src/utils/helpers/auth';
import type { History } from '@remix-run/router';
import { createBrowserHistory } from '@remix-run/router';
import { setIcReferrer } from 'src/utils/index';

// see: https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219
export const historyInstance: History =
  typeof window === 'undefined'
    ? null
    : createBrowserHistory({ window, v5Compat: true });

export const goPageLogin = () => {
  const pushUrl: Path = isAffiliate() ? '/affiliate/login' : '/login';
  goToPath(pushUrl);
};

export const goPageLogout = () => {
  const routes: { [key in Roles]: Path } = {
    SUPER_ADMIN: '/admin/logout',
    ADMIN: '/admin/logout',
    CLIENT: '/client/logout',
    AFFILIATE: '/affiliate/logout',
  };
  goToPath(routes[getUserRole()]);
};

export const goToPath = (path: Path, state = {}, keepQueryString = true) => {
  const searchParams = new URLSearchParams(window.location.search);
  setIcReferrer(searchParams);

  historyInstance.push(
    {
      pathname: path,
      search: keepQueryString ? searchParams.toString() : undefined,
    },
    state
  );
};
