import Cookie from 'js-cookie';
import { formatCurrency as format } from 'src/utils/format-currency';
import { defaultCookieTTL, tokenCookie } from 'src/config';
import { loadJwt } from './helpers/auth';
import type { Currencies } from 'src/@enums';
import { CryptoCurrencies } from 'src/@enums';

export const errorMsg = (error) =>
  (error.response && error.response?.data?.reason) || error.message;
export const setToken = (jwt: string) => {
  Cookie.set(tokenCookie, jwt, { expires: defaultCookieTTL });
};

export const addToken = (data: {
  data?: unknown;
  filter?: object;
  jwt?: string;
  token?: string;
  type?: string;
}): {
  data?: unknown;
  filter?: object;
  jwt?: string;
  token?: string;
  type?: string;
} => {
  const jwt = data?.jwt || loadJwt();

  return { ...data, jwt };
};

export const formatCurrency = (
  amount: number,
  currency: string,
  fieldName?: string,
  hideSymbol = false
) => {
  if (amount === undefined || !currency) {
    return '';
  }

  const fiatFractionDigits = fieldName === 'price' ? 0 : 2;
  return format(
    currency as Currencies,
    amount,
    false,
    fiatFractionDigits,
    hideSymbol
  );
};

export const notEmptyValues = <T extends object>(values: T): Partial<T> => {
  const payload = {};
  Object.keys(values).forEach((key) => {
    if (key in values) {
      payload[key] = values[key];
    }
  });
  return payload;
};

export const parseClient = (client) => {
  const {
    userAccountInfo: {
      id,
      email,
      currency,
      roleType,
      status,
      createdAt,
      affiliateId,
      emailVerification,
      lastLogin,
      source,
      riskLevel,
      status2fa,
      pendingAt,
      loggedFromBlacklistedIp,
    },
    userPersonalInfo: {
      firstName,
      lastName,
      phone,
      isMatchedName,
      isMatchedPhone,
    },
    userAddressInfo,
    cryptoBalances = [],
  } = client;
  const { city, country, countryCode, sepa } = userAddressInfo || {};
  return {
    id,
    email,
    currency,
    roleType,
    firstName,
    lastName,
    source,
    status,
    createdAt,
    affiliateId,
    emailVerification,
    lastLogin,
    riskLevel,
    status2fa,
    phone,
    city,
    country,
    countryCode,
    sepa,
    pendingAt,
    isMatchedName,
    isMatchedPhone,
    loggedFromBlacklistedIp,
    cryptoBalances,
  };
};

export const isObjectEmpty = (object: object) =>
  object && Object.keys(object).length === 0;

export const isObject = (obj: unknown) =>
  obj && !Array.isArray(obj) && typeof obj === 'object';

export const getCurrencyFractionDigit = (currency: string) => {
  switch (currency) {
    case CryptoCurrencies.BTC:
      return 8;
    default:
      return 2;
  }
};

export const formatCurrencyAmount = (
  amount: number | string | null | undefined,
  currency: string
) => {
  if (!amount) {
    return undefined;
  }
  return `${Number(amount).toLocaleString('en-US', {
    maximumFractionDigits: getCurrencyFractionDigit(currency),
  })} ${currency}`;
};

export const getSignedPhoneNumber = (phone: string) => {
  if (!phone) {
    return null;
  }
  if (phone.startsWith(' ')) {
    return phone.replace(/^\s/, '+');
  }
  if (!phone.startsWith('+')) {
    return `+${phone}`;
  }
  return phone;
};

export function setIcReferrer(searchParams: URLSearchParams) {
  const icReferrer = sessionStorage.getItem('icReferrer');
  if (icReferrer && !searchParams.get('referrer')) {
    searchParams.set('ic-referrer', icReferrer);
  }
}

export function isSubPath(mainPath: string, subPath: string): boolean {
  const mainArray = mainPath.split('/');
  const subArray = subPath.split('/');
  if (mainArray.length > subArray.length) return false;
  for (let i = 0; i < mainArray.length; i++) {
    if (subArray[i] !== mainArray[i]) return false;
  }
  return true;
}
