import Cookie from 'js-cookie';
import { tokenCookie } from 'src/config';
import {
  TABLE_COLUMN_SIZING,
  TABLE_FILTERS,
  TABLE_PAGER_LIMIT,
} from 'src/utils/constants/localStorage';
import { jwtDecode } from 'src/utils/jwtDecode';
import { logSentry } from 'src/utils/helpers/logging';
import { goPageLogout } from 'src/utils/pageNavigator';
import type { JwtPayload } from './@types';

export const authCheck = () => {
  const cookie = Cookie.get(tokenCookie);
  return cookie && cookie !== '';
};

export const loadJwt = () => {
  return Cookie.get(tokenCookie);
};

export const getUserToken = (): string => {
  return loadJwt();
};

export const isTokenValid = (token: string) => {
  return Boolean(jwtDecode(token));
};

export const clearLocalStorage = () => {
  /* remove only table keys */
  try {
    const prefixes = [TABLE_FILTERS, TABLE_COLUMN_SIZING, TABLE_PAGER_LIMIT];
    const localStorageKeys = Object.keys(localStorage) || [];
    if (localStorageKeys.length > 0) {
      Object.keys(localStorage).forEach((key) => {
        const isExist = prefixes.some((p) => key.indexOf(p) > -1);
        if (isExist) {
          localStorage.removeItem(key);
        }
      });
    }
  } catch (err) {
    logSentry(err, { functionName: 'clearLocalStorage' });
  }
};

export const logout = () => {
  Cookie.remove(tokenCookie);
  clearLocalStorage();
};

export const getUserRole = (jwt?: string) => {
  const token = jwt ? jwt : getUserToken();

  if (!token || !isTokenValid(token)) {
    return undefined;
  }

  const { roleType } = jwtDecode<JwtPayload>(token);
  return roleType;
};

export const getUserId = (): number => {
  const token = getUserToken();

  if (!isTokenValid(token)) {
    goPageLogout();
    return;
  }

  if (token) {
    const { userId } = jwtDecode<JwtPayload>(token);
    return userId;
  }
  return null;
};
