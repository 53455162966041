var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"instacoins@116.0.0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/react';
import axios from 'axios';
import { SENTRY_DSN_URL, VERSION } from './src/config/features';
import { loadJwt } from './src/utils/helpers/auth';
import { getCurrentEnvironment } from './src/utils/helpers/environment';
import { jwtDecode } from './src/utils/jwtDecode';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { useEffect } from 'react';

const currentEnv = getCurrentEnvironment();
const errorTypesAndValuesToLogAsInfo = [
  'Loading CSS chunk',
  "Unexpected token '<'",
  "ChunkLoadError'",
];

Sentry.init({
  dsn: SENTRY_DSN_URL,
  autoSessionTracking: false,
  // Only send errors in production
  enabled: ['production'].includes(currentEnv),
  ignoreErrors: [
    // ignore all non-error errors
    'Non-Error promise rejection captured',
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: 1.0,

  beforeSend: (event, hint) => {
    // We don't want to send errors that are caused by timeouts
    if (hint.originalException === 'Timeout') return null;

    const jwt = loadJwt();
    if (jwt) {
      const userId = jwtDecode<{ userId: number }>(jwt)?.userId;
      Sentry.setUser({ id: userId });
      Sentry.setContext('jwt', { jwt });
    }

    event.tags = {
      ...event.tags,
      isApiError: hint.originalException
        ? axios.isAxiosError(hint.originalException as Error)
        : false,
    };

    if (event.extra?.asWarning) {
      event.level = 'info';
    }
    event.exception?.values?.forEach((exception) => {
      if (errorTypesAndValuesToLogAsInfo.includes(exception.type!)) {
        event.level = 'info';
      }
    });
    return event;
  },
  release: `instacoins@${VERSION}`,
  environment: currentEnv,
});
