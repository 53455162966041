import type { ReactNode } from 'react';
import { useState, useEffect } from 'react';

interface Props {
  children: ReactNode;
}

const SafeHydrate = ({ children }: Props): React.ReactElement => {
  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }
  return <div suppressHydrationWarning>{children}</div>;
};

export default SafeHydrate;
