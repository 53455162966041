export function isProduction() {
  if (typeof window === 'undefined') {
    return false;
  }

  if (process.env.NODE_ENV === 'test') {
    return false;
  }

  return window?.location.href.includes('app.');
}

const isStageEnvironment = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  if (process.env.NODE_ENV === 'test') {
    return true;
  }

  return window?.location.href.includes('stage.');
};

const getTestEnvironment = (): string | false => {
  if (typeof window === 'undefined') {
    return false;
  }

  const [name] = window?.location?.hostname?.split('.') ?? [];

  return name ?? false;
};

export const getCurrentEnvironment = () => {
  if (isProduction()) {
    return 'production';
  }
  if (isStageEnvironment()) {
    return 'stage';
  }
  return getTestEnvironment() || 'localhost';
};
