/* eslint-disable typescript-sort-keys/string-enum */
export enum Currencies {
  BTC = 'BTC',
  EUR = 'EUR',
  EURD = 'EURD',
  GBP = 'GBP',
  GBPD = 'GBPD',
  USD = 'USD',
  USDD = 'USDD',
}

export enum CryptoCurrencies {
  BTC = 'BTC',
  USDD = 'USDD',
  GBPD = 'GBPD',
  EURD = 'EURD',
}

export enum CryptoCurrency {
  BTC = 'BTC',
}
