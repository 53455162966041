import type { AppProps } from 'next/app';
import Cookie from 'js-cookie';
import SafeHydrate from 'src/components/SafeHydrate';
import 'src/index.css';
import 'src/assets/css/app.css';
import 'src/assets/css/overpassfont.css';
import { useEffect } from 'react';
import { isExternalLink } from 'src/utils/isExternalLink';

type Props = AppProps;

if (
  process.env.NEXT_PUBLIC_IS_API_MOCKED === 'true' &&
  process.env.NODE_ENV !== 'production' &&
  typeof window !== 'undefined'
) {
  import('src/mocks/browser').then(({ worker }) => {
    worker.start();
  });
}

export default function MyApp({ Component, pageProps }: Props) {
  useEffect(() => {
    const icReferrerCookie = Cookie.get('icReferrer');
    const { referrer = '' } = document;
    let mainReferrer = '';
    if (icReferrerCookie) {
      mainReferrer = icReferrerCookie;
      Cookie.remove('icReferrer', { path: '/', domain: '.instacoins.com' });
    } else {
      mainReferrer = referrer;
    }
    if (mainReferrer && isExternalLink(mainReferrer)) {
      sessionStorage.setItem('icReferrer', mainReferrer);
    }
  }, []);
  return (
    <SafeHydrate>
      <Component {...pageProps} />
    </SafeHydrate>
  );
}
