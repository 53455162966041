import { isProduction } from 'src/utils/helpers/environment';

export enum TransactionStatuses {
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
  PENDING = 'PENDING',
  PREAUTH_CANCELLED = 'PREAUTH_CANCELLED',
  PREAUTH_FINISHED = 'PREAUTH_FINISHED',
  PREAUTH_STARTED = 'PREAUTH_STARTED',
  REFUND_APPROVED = 'REFUND_APPROVED',
  REFUND_PROCESSED = 'REFUND_PROCESSED',
  REFUND_REQUESTED = 'REFUND_REQUESTED',
  WITHDRAWAL_ADMIN_AUTHORISATION_PENDING = 'WITHDRAWAL_ADMIN_AUTHORISATION_PENDING',
  WITHDRAWAL_AUTHORISATION_EXPIRED = 'WITHDRAWAL_AUTHORISATION_EXPIRED',
  WITHDRAWAL_AUTHORISATION_PENDING = 'WITHDRAWAL_AUTHORISATION_PENDING',
  WITHDRAWAL_CANCELLED_BY_ADMIN = 'WITHDRAWAL_CANCELLED_BY_ADMIN',
  WITHDRAWAL_CANCELLED_BY_SYSTEM = 'WITHDRAWAL_CANCELLED_BY_SYSTEM',
  WITHDRAWAL_CANCELLED_BY_USER = 'WITHDRAWAL_CANCELLED_BY_USER',
  WITHDRAWAL_CRYPTO_TX_FAILED = 'WITHDRAWAL_CRYPTO_TX_FAILED',
  WITHDRAWAL_CRYPTO_TX_SENT = 'WITHDRAWAL_CRYPTO_TX_SENT',
  WITHDRAWAL_IN_PROGRESS = 'WITHDRAWAL_IN_PROGRESS',
  WITHDRAWAL_PENDING = 'WITHDRAWAL_PENDING',
  WITHDRAWAL_PENDING_MANUAL_VERIFICATION = 'WITHDRAWAL_PENDING_MANUAL_VERIFICATION',
}

export const recaptchaSiteKey =
  process.env.NODE_ENV === 'production'
    ? '6LcyFW4UAAAAAMGm78rm7ooj6cXA90Fp-BCOH0Lp'
    : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

export const clientStatusTypes = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  MISSING_DOCS: 'MISSING_DOCS',
  INACTIVE: 'INACTIVE',
  ARCHIVED: 'ARCHIVED',
};

export const defaultCookieTTL = 1;
export const tokenCookie = 'token';

const getBaseUrl = () => {
  if (
    process.env.NODE_ENV === 'test' ||
    process.env.NEXT_PUBLIC_IS_PROXY === 'true'
  ) {
    return 'http://localhost:3005';
  } else {
    if (typeof window !== 'undefined') {
      return `${window?.location.origin}/api/v1`;
    } else {
      return '/api/v1';
    }
  }
};

// Returns the base url of API
export const BASE_URL = getBaseUrl();

/** Admin client notes configs */
export const clientNotes = Object.freeze({
  maxAttachmentsPerNote: 6,
});

export const GOOGLE_ANALYTICS_TAG = 'UA-128218859-1';

export const ADROLL_PARAMS = [
  'QLBVUNFCVVADJL7P3E3NPI',
  'UZPNRV2XBRCLTHOSOCEFTB',
  false,
];

export const AFFILIATE_PAYOUT_MINIMUM_VALUE = isProduction() ? 100 : 1;
