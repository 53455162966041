export enum TransactionTypes {
  CARD = 'card',
  WIRE = 'wire',
}

export enum ClientTransactionType {
  ADJUSTMENT = 'ADJUSTMENT',
  BUY = 'BUY',
  CUSTODY_FEE = 'CUSTODY_FEE',
  TRANSFER = 'TRANSFER',
}
