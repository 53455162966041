import type { Currencies } from 'src/@enums';
import getSymbolFromCurrency from 'currency-symbol-map';
import { getCurrencyFractionDigit } from './index';
import { CryptoCurrencies } from 'src/@enums';

export const formatCurrency = (
  currency?: CryptoCurrencies | Currencies,
  value = 0,
  spaceAfterSymbol = false,
  fiatFractionDigits = getCurrencyFractionDigit(currency),
  hideSymbol = false
) => {
  const sign = value < 0 ? '-' : '';
  const symbol = getSymbolFromCurrency(currency);

  if (
    [
      CryptoCurrencies.BTC,
      CryptoCurrencies.USDD,
      CryptoCurrencies.GBPD,
      CryptoCurrencies.EURD,
    ].includes(currency as CryptoCurrencies)
  ) {
    return `${sign}${
      value === 0
        ? 0
        : Math.abs(value).toFixed(getCurrencyFractionDigit(currency))
    } ${hideSymbol ? '' : currency}`;
  }

  if (!symbol) {
    return `${sign}${Math.abs(value).toLocaleString('en-GB', {
      minimumFractionDigits: fiatFractionDigits,
      maximumFractionDigits: fiatFractionDigits,
    })} ${currency || ''}`;
  }

  return `${sign}${hideSymbol ? '' : symbol}${
    !hideSymbol && (spaceAfterSymbol || /\w+/.test(symbol)) ? ' ' : ''
  }${Math.abs(value).toLocaleString('en-GB', {
    minimumFractionDigits: fiatFractionDigits,
    maximumFractionDigits: fiatFractionDigits,
  })}`;
};
