import decode from 'jwt-decode';

/**
 * Decode JWT and Return body as JSON.
 * Return undefined if given argument is not a valid JWT.
 */
export function jwtDecode<T>(jwt: string) {
  try {
    return decode<T>(jwt);
  } catch (_) {
    return undefined;
  }
}
